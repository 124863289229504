<template>
  <div class="mx-10">
    <div class="rounded-md flex items-center justify-between bg-gray-800 px-4 py-4 sm:bg-gray-200 w-full">
      <h3 class="text-2xl font-semibold text-gray-100 sm:text-gray-900">Skip Order Deflect Popup Settings</h3>
    </div>
    <div class="mt-8">
      <div v-if="loaded">
        <div class="overflow-hidden bg-white shadow sm:rounded-md mb-10 ">
          <ul class="divide-y divide-gray-100">

            <li class="p-4 text-gray-700" >
              <div class="mb-2">

              <div class="mb-1 text-gray-700 capitalize min-w-[150px]">Top CTA text</div>
                                <TextInput
                                  v-model="settings.top_cta_text"
                                  class="w-full block"
                                />
              </div><p class="text-sm">HTML tags supported</p>
            </li>

            <li class="p-4 text-gray-700">
              <div  class="flex items-center mb-2">
                <div class="mb-1 text-gray-700 capitalize min-w-[200px]">Is top CTA visible</div>
                <CheckboxInput
                  type="checkbox"
                  v-model="settings.is_top_cta_visible"
                  class="form-checkbox h-5 w-5"
                />
              </div>
            </li>



            <li class="p-4 text-gray-700" >
              <div class="mb-2">
              <div class="mb-1 text-gray-700 capitalize min-w-[150px]">Bottom CTA text</div>
                                <TextInput
                                  v-model="settings.bottom_cta_text"
                                  class="w-full block"
                                />
              </div>

            </li>
            <li class="p-4 text-gray-700" >
              <div class="mb-2">
              <div class="mb-1 text-gray-700 capitalize min-w-[150px]">Header text</div>
                                <TextInput
                                  v-model="settings.header_text"
                                  class="w-full block"
                                />
              </div><p class="text-sm">HTML tags supported</p>
            </li>

            <li class="p-4 text-gray-700">
              <div  class="flex items-center mb-2">
                <div class="mb-1 text-gray-700 capitalize min-w-[200px]">Is header text visible</div>
                <CheckboxInput
                  type="checkbox"
                  v-model="settings.is_header_text_visible"
                  class="form-checkbox h-5 w-5"
                />
              </div>
            </li>

            <li class="p-4 text-gray-700">
              <div  class="flex items-center mb-2">
                <div class="mb-1 text-gray-700 capitalize min-w-[200px]">Is reasons list visible</div>
                <CheckboxInput
                  type="checkbox"
                  v-model="settings.is_reasons_visible"
                  class="form-checkbox h-5 w-5"
                />
              </div>
            </li>

            <li class="p-4 text-gray-700" >
              <div class="mb-2">
                <div class="mb-1 text-gray-700 capitalize min-w-[150px]">Survey reasons header text</div>
                <TextInput
                  v-model="settings.survey_reasons_header_text"
                  class="w-full block"
                />
              </div>
              <p class="text-sm">HTML tags supported</p>
            </li>

            <li class="p-4 text-gray-700">
              <div  class="flex items-center mb-2">
                <InputGroup label="Top image" :error="getError('top_image')">
                  <ImageField v-model="settings.top_image_file" :default-image="$get(settings, 'top_image_url')" />
                </InputGroup>
              </div>
            </li>

            <li class="p-4 text-gray-700" >
              <div class="mb-2">
                <div class="mb-1 text-gray-700 capitalize min-w-[150px]">Enabled for order week numbers:</div>
                <TextAreaInput
                  v-model="textWeekNumbers"
                  class="w-full block"
                />
              </div>
              <p class="text-sm">e.g.: 2024 Week 36</p>
            </li>

            <li class="p-4 text-gray-700">
              <div  class="flex items-center mb-2">
                <div class="mb-1 text-gray-700 capitalize min-w-[250px]">Is enabled only for specific users</div>
                <CheckboxInput
                  type="checkbox"
                  v-model="settings.only_for_specific_users"
                  class="form-checkbox h-5 w-5"
                />
              </div>
            </li>


            <li class="p-4 text-gray-700" >
              <div class="mb-2">
                <div class="mb-1 text-gray-700 capitalize min-w-[150px]">Enabled for, user IDs:</div>
                <TextAreaInput
                  :readonly="!settings.only_for_specific_users"
                  v-model="textUserIds"
                  class="w-full block"
                />
              </div>

            </li>
          </ul>
        </div>

        <div class="flex">
          <button-loader label="Save" :loading="saving" @click="save" />
        </div>
      </div>
      <loader v-else />
    </div>
</div>
</template>
<script>

import { mapActions } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
import CheckboxInput from "@/components/global/input/CheckboxInput.vue";
import ImageField from '@/components/products/ImageField.vue'

export default {
  mixins: [
    HasErrors
  ],
  components: {
    ImageField,
    CheckboxInput
  },
  data: () => ({
    textUserIds: '',
    textWeekNumbers: '',
    loaded: false,
    saving: false,
    settings:{
      top_image_file: null,
      only_for_specific_users: false,
      top_cta_text: null,
      bottom_cta_text: null,
      header_text: null,
      survey_reasons_header_text: null,
      top_image_url: null,
      is_top_cta_visible: null,
      is_header_text_visible: null,
      is_reasons_visible: null,
      user_ids: [],
      allowed_week_numbers: [],
    }
  }),
  async mounted() {
    try {
      this.loaded = false
      this.settings = await this.fetch()
      this.textUserIds = this.arrayToText(this.settings.user_ids)
      this.textWeekNumbers = this.arrayToText(this.settings.allowed_week_numbers)

      this.loaded = true
    } catch (err) {
      this.errorMessage(err?.response?.data?.message || err)
    }
  },
  computed: {
  },
  methods: {
    ...mapActions({
      fetch: 'custom-skip-order-deflect-popup-settings/fetch',
      update: 'custom-skip-order-deflect-popup-settings/update',
    }),
    arrayToText(array){
      return array.join('\n');
    },
    textToArray(text){
      return text
        .split('\n')
        .map(item => item.trim())
        .filter(item => item);
    },
    async save() {
      this.saving = true;
      try {
        this.settings.user_ids = JSON.stringify(this.textToArray(this.textUserIds))
        this.settings.allowed_week_numbers = this.textToArray(this.textWeekNumbers)
        this.saving = false
        await this.wrapSubmit(this.update(this.settings))
        this.successMessage('Success', 'The settings are updated')
      } catch (err) {
        this.errorMessage('Error', this.$get(err, 'response.data.message'))
      }
      this.saving = false
    }
  }
}
</script>
